import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useState } from "react"
import { z } from "zod"

export const usecasesAlternativeSchema = z.object({
	component: z.literal("usecases_alternative"),
	videos: z.string().array(),
	style: z.literal("large").optional(),
	background_images: z
		.object({
			odd: z.string(),
			even: z.string(),
		})
		.optional(),
})

type UsecasesAlternativeProps = z.infer<
	typeof usecasesAlternativeSchema
>

export function UsecasesAlternative(
	props: UsecasesAlternativeProps,
) {
	const { videos, style } = props
	const [unmuteAll, setUnmuteAll] = useState(false)

	if (style && style === "large") {
		return <UseCasesLarge {...props} />
	}
	return (
		<section
			className={clsx(
				"flex items-center overflow-x-auto py-[35px] tablet:py-[40px]",
				"no-scrollbar gap-[17px] desktop:gap-[20px] desktop:py-[120px]",
				"px-[20px] tablet:justify-center tablet:px-0",
			)}>
			{videos.map((video, index) => {
				return (
					<div
						onMouseMove={() => {
							if (!unmuteAll) return
							const video = document.getElementById(
								`usecase-video-${index}`,
							)
							if (video instanceof HTMLVideoElement) {
								if (!video) return

								video.muted = false
							}
						}}
						onMouseLeave={() => {
							const video = document.getElementById(
								`usecase-video-${index}`,
							)
							if (video instanceof HTMLVideoElement) {
								if (!video) return

								video.muted = true
							}
						}}
						className="group relative z-10"
						key={index}>
						<video
							id={`usecase-video-${index}`}
							src={video + "#t=0.001"}
							loop
							autoPlay
							playsInline
							muted
							className={clsx(
								"h-[201px] w-[125px] shrink-0 rounded-[14px] object-cover",
								"bg-blue-100 tablet:h-[272px] tablet:w-[169px]",
								"desktop:h-[362px] desktop:w-[224px]",
							)}
						/>
						<button
							onClick={() => {
								setUnmuteAll((oldState) => !oldState)
								const video = document.getElementById(
									`usecase-video-${index}`,
								)
								if (video instanceof HTMLVideoElement) {
									if (!video) return

									if (unmuteAll) {
										video.muted = true
									} else {
										video.muted = false
									}
								}
							}}
							className={clsx(
								"hidden bg-color-popup group-hover:block",
								"absolute bottom-2 rounded-[4px] p-[4px]",
								"right-2 z-20",
							)}>
							<img
								src={assetUrl(
									`/general/${
										unmuteAll
											? "sound-on-v2"
											: "sound-off-v2"
									}.webp`,
								)}
								alt="sound icon"
								className="h-[24px] w-[24px]"
							/>
						</button>
					</div>
				)
			})}
		</section>
	)
}

function UseCasesLarge(props: UsecasesAlternativeProps) {
	const { videos, background_images } = props
	const [unmuteAll, setUnmuteAll] = useState(false)

	return (
		<section
			className={clsx(
				"flex w-full py-[16px] tablet:py-[40px]",
				"no-scrollbar desktop:py-[60px]",
				" desktop:px-[calc(50vw-702px)]",
			)}>
			<div
				className={clsx(
					"flex w-full flex-row items-center overflow-x-auto",
					"no-scrollbar gap-[14px] tablet:gap-3 desktop:gap-[24px]",
					" justify-start px-5 tablet:justify-center desktop:justify-start desktop:px-[30px]",
				)}>
				{videos.map((video, index) => {
					return (
						<div
							className={clsx(
								"h-[250px] w-[158px] shrink-0 overflow-hidden rounded-[6px]",
								"object-cover tablet:rounded-[8px] desktop:rounded-[14px]",
								"tablet:h-[290px] tablet:w-[173px]",
								"desktop:h-[492px] desktop:w-[318px]",
								"group relative z-10 p-[10px] tablet:p-3 desktop:px-[18px] desktop:py-[15px]",
							)}
							onMouseMove={() => {
								if (!unmuteAll) return
								const video = document.getElementById(
									`usecase-video-${index}`,
								)
								if (video instanceof HTMLVideoElement) {
									if (!video) return

									video.muted = false
								}
							}}
							onMouseLeave={() => {
								const video = document.getElementById(
									`usecase-video-${index}`,
								)
								if (video instanceof HTMLVideoElement) {
									if (!video) return

									video.muted = true
								}
							}}
							key={index}>
							<video
								id={`usecase-video-${index}`}
								src={video + "#t=0.001"}
								loop
								playsInline
								autoPlay
								muted
								className={clsx(
									"relative z-10 h-full w-full shrink-0 rounded-[2px]",
									"object-cover tablet:rounded-[4px] desktop:rounded-[8px]",
								)}
							/>
							<button
								onClick={() => {
									setUnmuteAll((oldState) => !oldState)
									const video = document.getElementById(
										`usecase-video-${index}`,
									)
									if (video instanceof HTMLVideoElement) {
										if (!video) return

										if (unmuteAll) {
											video.muted = true
										} else {
											video.muted = false
										}
									}
								}}
								className={clsx(
									"hidden bg-color-popup group-hover:block",
									"absolute bottom-6 rounded-[4px]",
									"right-[26px] z-20 h-7 w-7 p-1",
								)}>
								<img
									src={assetUrl(
										`/general/${
											unmuteAll
												? "sound-on-v2"
												: "sound-off-v2"
										}.webp`,
									)}
									alt="sound icon"
									className="h-full w-full"
								/>
							</button>

							<img
								style={{
									display:
										index % 2 === 0 ? "none" : "block",
								}}
								src={
									background_images
										? background_images.odd
										: assetUrl("/feature/odd-bg.webp")
								}
								alt="background odd"
								className="absolute left-0 top-0 z-0 h-full w-full object-cover"
							/>

							<img
								style={{
									display:
										index % 2 !== 0 ? "none" : "block",
								}}
								src={
									background_images
										? background_images.even
										: assetUrl("/feature/even-bg.webp")
								}
								alt="background even"
								className="absolute left-0 top-0 h-full w-full"
							/>
						</div>
					)
				})}
			</div>
		</section>
	)
}
